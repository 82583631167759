import Header from './components/Header';
import Lottie from './components/Lottie';
import TheDate from './components/TheDate';
import Carousel from './components/Carousel';
import HorizontalScrolling from './components/HorizontalScrolling';
import Scrolly from './components/Scrolly';
import Youtube from './components/Youtube';
import TitleAnim from './components/TitleAnim';

export default class ComponentFactory {
  constructor() {
    this.componentList = {
      Header,
      Lottie,
      TheDate,
      Carousel,
      HorizontalScrolling,
      Scrolly,
      Youtube,
      TitleAnim,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');
    for (let i = 0; i < components.length; i++) {
      const element = components[i];

      const componentName = element.dataset.component;
      if (this.componentList[componentName]) {
        new this.componentList[componentName](element);
      }
    }
  }
}
