import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

export default class HorizontalScrolling {
  constructor(element) {
    this.element = element;
    this.container = document.querySelector('.js-container');

    this.init();
  }

  init() {
    window.addEventListener('resize', () => {
      ScrollTrigger.refresh();
    });

    window.addEventListener('load', () => {
      ScrollTrigger.refresh();
    });

    /**----------------------------------------- */

    const getScrollAmount = () => {
      let containerWidth = this.container.scrollWidth;
      return -(containerWidth - window.innerWidth);
    };

    const tween = gsap.to(this.container, {
      x: getScrollAmount,
      duration: 5,
      ease: 'none',
    });

    ScrollTrigger.create({
      trigger: '.js-projects-wrapper',
      start: 'top 0',
      end: () => `+=${getScrollAmount() * -1}`,
      pin: true,
      animation: tween,
      scrub: 1,
      invalidateOnRefresh: true,
      marker: false,
    });
  }
}
