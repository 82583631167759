import LottieWeb from 'lottie-web';
import { create } from '@lottiefiles/lottie-interactivity';

export default class Lottie {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    this.loadLottie();
  }

  loadLottie() {
    const animation = LottieWeb.loadAnimation({
      container: this.element,
      name: `${this.element.dataset.name}`,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `assets/lotties/${this.element.dataset.name}.json`,
    });

    if ('scroll' in this.element.dataset) {
      create({
        player: animation,
        mode: 'scroll',
        actions: [
          {
            visibility: [0, 1.0],
            type: 'seek',
            frames: [
              parseInt(this.element.dataset.start),
              parseInt(this.element.dataset.end),
            ],
          },
        ],
      });
    } else if ('hover' in this.element.dataset) {
      create({
        player: animation,
        mode: 'cursor',
        actions: [
          {
            type: 'hover',
            forceFlag: false,
            state: 'loop',
            frames: [
              parseInt(this.element.dataset.start),
              parseInt(this.element.dataset.end),
            ],
          },
        ],
      });
    }
  }
}
