export default class TitleAnim {
  constructor() {
    this.origTitle = 'Rosalie Blanchet';
    this.animatedTitle = null;
    this.timer = null;
    this.currentState = false;

    this.emojis = ['😄', '👀', '🐔', '😊', '🚙', '😺', '✋', '👑', '💛', '🌈'];
    this.animationCount = 0;

    this.init();
  }

  init() {
    // Change page title on blur
    window.addEventListener('blur', this.animateTitle.bind(this));

    // Change page title back on focus
    window.addEventListener('focus', this.restoreTitle.bind(this));
  }

  animateTitle() {
    this.origTitle = document.title;
    this.timer = setInterval(() => this.startAnimation(), 2000);
  }

  startAnimation() {
    this.animatedTitle =
      this.emojis[this.animationCount % this.emojis.length] +
      ' Hey! reviens par ici ';
    document.title = this.currentState ? this.origTitle : this.animatedTitle;
    this.currentState = !this.currentState;
    this.animationCount++;
  }

  restoreTitle() {
    clearInterval(this.timer);
    document.title = this.origTitle;
  }
}
