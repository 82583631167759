export default class TheDate {
  constructor(element) {
    this.element = element;
    this.container = this.element;

    this.init();
  }

  init() {
    const date = new Date();
    const theDay = date.getDate();
    const theMonth = date.toLocaleString('fr', { month: 'long' });

    const day = document.createElement('p');
    day.innerText = theDay;

    const dateContainer = this.container.querySelector('.the__date');
    dateContainer.appendChild(day);
    //---------------------------------------------------------

    const statusContainer = this.container.querySelector('.the__status');

    const month = document.createElement('p');
    month.innerText = theMonth;
    statusContainer.appendChild(month);

    const statue = document.createElement('p');
    statue.innerText = 'Prête à être\u00A0embauchée';
    statusContainer.appendChild(statue);
  }
}
