import Swiper from 'swiper/bundle';

export default class Carousel {
  constructor(element) {
    this.element = element;

    this.options = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),

        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
    };

    this.init();
  }

  init() {
    this.setOption();

    new Swiper(this.element, this.options);
  }

  setOption() {
    const variant = this.element.dataset.variant;

    if (variant == 'split') {
      this.options.breakpoints = {
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        780: {
          slidesPerView: 2.5,
        },
        375: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      };
    }

    if (variant == 'cards') {
      this.options = {
        effect: 'cards',
        grabCursor: true,
      };
    }

    if (variant == 'creative') {
      this.options = {
        effect: 'creative',
        creativeEffect: {
          limitProgress: 4,

          prev: {
            translate: [0, 0, -12],
          },
          next: {
            translate: [12, 12, 0],
          },
        },
        loop: true,
      };
    }

    if (variant == 'fade') {
      this.options = {
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 1000,
      };
    }

    if ('autoplay' in this.element.dataset) {
      this.options.autoplay = {
        delay: 1500,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      };
    }

    if ('loop' in this.element.dataset) {
      this.options.loop = {
        loop: true,
      };
    }

    if ('gap' in this.element.dataset) {
      this.options = {
        spaceBetween: parseInt(this.element.dataset.gap),
      };
    }
  }
}
