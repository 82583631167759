/**
 * Composante Youtube de TimTools
 */

export default class Youtube {
  /** 
     * Methode appelle lors de l'instanciation
      @param {HTMLElement} element - l'élément html qui doit etre utiliser par la composante
      *
    */
  constructor(element) {
    this.element = element;

    this.videoContainer = this.element.querySelector('.js-youtube');
    this.poster = this.element.querySelector('.js-poster');
    this.videoId = this.element.dataset.videoId;
    /** si on a une vignette on retourne true si non, false */
    this.autoplay = this.poster ? 1 : 0;
    /** par défault, les controles ne sont pas visible */
    this.showControls = 0;
    this.playerReady = false;
    /** stock les copies des instances youtube dans un tableau statique*/
    Youtube.instances.push(this);

    if (this.videoId) {
      Youtube.loadScript();
    } else {
      console.error('vous devez spécifier un id');
    }
  }
  /**
   *
   * Methode statique qui appele la librairy de youtube
   */
  static loadScript() {
    if (!Youtube.scriptIsLoading) {
      Youtube.scriptIsLoading = true;
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
    }
  }

  /**
   *
   * Méthode d'initialisation appeler pour chaque instance de Youtube presente dans la page
   */

  init() {
    this.initPlayer = this.initPlayer.bind(this);
    /** modifie la propriéte controle de youtube selon la présence du data-controls */
    if ('controls' in this.element.dataset) {
      this.showControls = 1;
    }

    if (this.poster) {
      this.element.addEventListener('click', this.initPlayer);
    } else {
      this.initPlayer();
    }
  }
  /**
   *
   * Methode qui instancie un nouveau lecteur youtube
   * @param {PointerEvent} event - Parametre qui est passer lors du clique
   *  */
  initPlayer(event) {
    if (event) {
      this.element.removeEventListener('click', this.initPlayer);
    }
    this.player = new YT.Player(this.videoContainer, {
      height: '100%',
      width: '100%',
      videoId: this.videoId,
      playerVars: {
        rel: 0,
        autoplay: this.autoplay,
        controls: this.showControls,
      },
      events: {
        onReady: () => {
          this.playerReady = true;

          const observer = new IntersectionObserver(this.watch.bind(this), {
            rootMargin: '0px 0px 0px 0px',
          });
          observer.observe(this.element);
        },
        onStateChange: (event) => {
          if (event.data == YT.PlayerState.PLAYING) {
            Youtube.pauseAll(this);
          } else if (event.data == YT.PlayerState.ENDED) {
            /** lorsque le video fini, revient au debut et pause la video */
            this.player.seekTo(0);
            this.player.pauseVideo();
          }
        },
      },
    });
  }
  /**
   *
   * Methode qui regarde les elements qui sont visible dans le navigateur
   * */
  watch(entries) {
    /** si le lecteur existe et quil nest pas dans le viewport : pause */
    if (this.playerReady && !entries[0].isIntersecting) {
      this.player.pauseVideo();
    }
  }
  /**
   *
   * Methode qui boucle dans tous les instances de Youtube de la page
   * */
  static initAll() {
    document.documentElement.classList.add('is-video-ready');

    for (let i = 0; i < Youtube.instances.length; i++) {
      const instance = Youtube.instances[i];
      instance.init();
    }
  }
  /**
     *
     * Methode statique qui pause tous les videos sauf celui qui est cliqué
     
     */
  static pauseAll(currentInstance) {
    for (let i = 0; i < Youtube.instances.length; i++) {
      const instance = Youtube.instances[i];
      if (instance.playerReady && instance !== currentInstance) {
        instance.player.pauseVideo();
      }
    }
  }
}

/** tableau centralisé qui contient les instances de Youtube presente dans la page */
Youtube.instances = [];
window.onYouTubeIframeAPIReady = Youtube.initAll;
