export default class Header {
  constructor(element) {
    this.element = element;
    this.scrollPosition = 0;
    this.scrollLimit = 0;
    this.lastScrollPosition = 0;
    this.html = document.documentElement;
    /*close nav*/
    this.navItems = this.element.querySelectorAll('.nav-primary__item');

    this.init();
    this.initNavMobile();
  }

  init() {
    if ('scrollLimit' in this.element.dataset) {
      this.scrollLimit = this.element.dataset.scrollLimit;
    }

    window.addEventListener('scroll', this.onScroll.bind(this));

    for (let i = 0; i < this.navItems.length; i++) {
      const item = this.navItems[i];

      item.addEventListener('click', this.removeNav.bind(this));
    }
  }

  onScroll(event) {
    this.lastScrollPosition = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;

    if (!('notHidding' in this.element.dataset)) {
      this.setHeaderState();
      this.setDirectionState();
    }
  }

  setHeaderState() {
    const scrollHeight = document.scrollingElement.scrollHeight;

    if (this.scrollPosition > scrollHeight * this.scrollLimit) {
      this.html.classList.add('header-is-hidden');
    } else {
      this.html.classList.remove('header-is-hidden');
    }
  }

  setDirectionState() {
    if (this.scrollPosition >= this.lastScrollPosition) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.remove('is-scrolling-down');
      this.html.classList.add('is-scrolling-up');
    }
  }

  initNavMobile() {
    const toggle = this.element.querySelector('.js-toggle');
    toggle.addEventListener('click', this.onToggleNav.bind(this));
  }

  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }

  removeNav() {
    this.html.classList.remove('nav-is-active');
  }
}
